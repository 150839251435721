<template>
  <li>
    {{ libelle }} [{{ prix }} {{ currency }}]
  </li>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: {
        activityUid: {type: String}
    },
    data(){
        return {

        }
    },
    computed: {
        ...mapGetters({
            activities: 'sanitaire/toilettageActivities'
        }),
        activiteObjet(){
            return this.activities.find(item => item.uid === this.activityUid)
        },
        hasActivity(){
            return this.activiteObjet !== null && this.activiteObjet !== undefined
        },
        libelle(){
            return this.hasActivity ?  this.activiteObjet.libelle: ''
        },
        prix(){
            return this.hasActivity ? this.activiteObjet.prix:0 
        },
        currency(){
            return this.hasActivity? this.activiteObjet.currency: ''
        }
        
    }

}
</script>

<style>

</style>